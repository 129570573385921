import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { notification, Button } from 'antd';
import Parameters from 'shared/parameters';

import LoginForm from './LoginForm';

import './Login.scss';
import logoIcon from 'shared/images/logo_white.svg';
import logoText from 'shared/images/text_white.svg';

import { AuthConsumer } from 'shared/contexts/AuthContext';

interface Props {
    location: any;
}

export default function Login({ location }: Props) {
    const [fields, setFields] = useState([] as any[]);
    const [loading, setLoading] = useState(false);

    const { from } = location.state || { from: { pathname: '/' } };
    return (
        <AuthConsumer>
            {({ isAuth, updateAuthToken }) =>
                isAuth ? (
                    <Redirect to={from} />
                ) : (
                    <div className="login">
                        <div className="__header">
                            <div className="__logo">
                                <img
                                    className="__icon"
                                    src={logoIcon}
                                    alt="Logo"
                                />
                                <img
                                    className="__text"
                                    src={logoText}
                                    alt="La Cigale"
                                />
                            </div>
                            <div className="__catch-phrase">
                                Livraison
                            </div>
                        </div>
                        <div className="__content">
                            <h1>Connectez-vous</h1>
                            <LoginForm
                                onChange={setFields}
                                fields={fields}
                                onLogin={(login: string, password: string) => {
                                    setLoading(true);
                                    fetch(`${Parameters.ApiUrl}/auth-tokens`, {
                                        method: 'POST',
                                        headers: {
                                            Accept: 'application/json',
                                            'Content-Type': 'application/json',
                                        },
                                        body: JSON.stringify({
                                            login,
                                            password,
                                        }),
                                    })
                                        .then(res => {
                                            if (!res.ok) {
                                                throw Error(res.statusText);
                                            }
                                            return res;
                                        })
                                        .then(res => res.json())
                                        .then(data => {
                                            updateAuthToken(data);
                                        })
                                        .catch(() => {
                                            notification.error({
                                                message:
                                                    'Invalid username or password',
                                            });
                                        })
                                        .finally(() => {
                                            setLoading(false);
                                        });
                                }}
                                loading={loading}
                            />
                            <Link to="/reset-password-request">
                                <Button
                                    className="__reset-password-btn"
                                    type="link"
                                    shape="round"
                                    size="large"
                                >
                                    Mot de passe oublié
                                </Button>
                            </Link>
                        </div>
                    </div>
                )
            }
        </AuthConsumer>
    );
}
