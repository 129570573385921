import React from "react";

import { ConfigProvider } from "antd";

// Apollo
import { ApolloProvider } from '@apollo/react-hooks';
import {client} from 'shared/services/ApolloClient';

import frFR from 'antd/es/locale/fr_FR';

import "antd/dist/antd.css";
import "./App.scss";

// Router
import ProtectedRoute from 'shared/components/ProtectedRoute';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { AuthProvider } from "shared/contexts/AuthContext";
import ErrorBoundary from 'shared/components/ErrorBoundary';

// Views
import Layout from "views/Layout";
import Login from "views/login/Login";
import ResetPasswordRequest from "shared/components/ResetPasswordRequest";

// moment
import moment from 'moment';
import 'moment/locale/fr'
moment.locale("fr");



export default function App() {
  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <ConfigProvider locale={frFR}>
            <AuthProvider>
                <Router>
                  <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/reset-password-request" component={ResetPasswordRequest} />
                    <ProtectedRoute path="/" component={Layout} />
                  </Switch>
                </Router>
            </AuthProvider>
        </ConfigProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
}