import React, { lazy } from 'react';
import { useMedia } from 'react-use';
import {
    useLocation,
    useHistory,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import {
    FileTextOutlined,
    SendOutlined,
    ShoppingOutlined,
} from '@ant-design/icons';
import ChangePasswordModal from 'shared/views/login/ChangePasswordModal';

import DesktopLayout, {
    MenuItem,
} from 'shared/components/layout/DesktopLayout';
import MobileLayout from 'shared/components/layout/MobileLayout';
import usePermissions, { ROLES } from 'shared/hooks/usePermissions';
import LoaderOverlay from 'shared/components/LoaderOverlay';

const Home = lazy(() => import('views/Home'));
const Delivery = lazy(() => import('views/delivery/Delivery'));
const Command = lazy(() => import('views/command/Command'));
const Kitchen = lazy(() => import('shared/views/kitchen/Kitchen'));
const SeniorCommands = lazy(
    () => import('shared/views/seniorCommand/SeniorCommands'),
);

export default function AppLayout() {
    const isMobile = useMedia('(max-width: 800px)');
    const { isGranted, loading: permissionsLoading } = usePermissions();
    const { pathname } = useLocation();
    const history = useHistory();

    if (permissionsLoading) {
        return <LoaderOverlay loading />;
    }

    const selectedMenu = pathname;
    function handleMenuClick(route: string) {
        if (route !== pathname) {
            history.push(route);
        }
    }

    const AppLayout = isMobile ? MobileLayout : DesktopLayout;
    return (
        <AppLayout
            menuItems={
                [
                    (isGranted(ROLES.MANAGER) ||
                        isGranted(ROLES.DELIVERY_MAN)) && {
                        route: '/',
                        icon: <SendOutlined />,
                        label: 'Mes tournées',
                    },
                    (isGranted(ROLES.MANAGER) ||
                        isGranted(ROLES.DELIVERY_MAN)) && {
                        route: '/saisie-seniors',
                        icon: <FileTextOutlined />,
                        label: 'Saisie seniors',
                    },
                    (isGranted(ROLES.MANAGER) ||
                        isGranted(ROLES.DELIVERY_MAN) ||
                        isGranted(ROLES.COOK)) && {
                        route: '/cuisine',
                        icon: <ShoppingOutlined />,
                        label: 'Cuisine',
                    },
                ].filter((m) => !!m) as MenuItem[]
            }
            logout
            selectedMenu={selectedMenu}
            onMenuClick={handleMenuClick}
        >
            <ChangePasswordModal />
            <Switch>
                <Route exact path="/">
                    {isGranted(ROLES.COOK) && !isGranted(ROLES.DELIVERY_MAN) && !isGranted(ROLES.MANAGER) ? (
                        <Redirect to="/cuisine" />
                    ) : (
                        <Home />
                    )}
                </Route>
                <Route
                    exact
                    path="/livraisons/:deliveryId"
                    component={Delivery}
                />
                <Route
                    exact
                    path="/livraisons/:deliveryId/:commandId"
                    component={Command}
                />
                {(isGranted(ROLES.MANAGER) ||
                    isGranted(ROLES.DELIVERY_MAN)) && (
                    <Route
                        exact
                        path="/saisie-seniors"
                        component={SeniorCommands}
                    />
                )}
                {(isGranted(ROLES.MANAGER) ||
                    isGranted(ROLES.DELIVERY_MAN) ||
                    isGranted(ROLES.COOK)) && (
                    <Route exact path="/cuisine" component={Kitchen} />
                )}
            </Switch>
        </AppLayout>
    );
}
